import Home from "./home/Home";
import MyProperties from "./myProperties/MyProperties";
import Login from "./login/Login";
import EsqueceuSenha from "./esqueceuSenha/EsqueceuSenha";
import RegistryReservation from "./registryReservation/RegistryReservation";
import StudioManagement from "./studioManagement/StudioManagement";
import CleaningAndMaintenance from "./cleaningAndMaintenance/CleaningAndMaintenance";
import Reservation from "./reservation/Reservation";
import RegisterOwners from "./registerOwners/RegisterOwners";
import MangerRegistryPage from "./managerRegistry/managerRegistryPage";

const pages = {
  Home,
  Login,
  EsqueceuSenha,
  MyProperties,
  RegistryReservation,
  StudioManagement,
  CleaningAndMaintenance,
  Reservation,
  RegisterOwners,
  MangerRegistryPage
};
export default pages;
