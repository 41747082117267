import styled from "styled-components";
import { ButtonContained, ButtonText, FontStyles } from "../../../components";
import { Spacing } from "../../../config";

const Container = styled.div(({ theme, noLine }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    padding: "16px 20px",
    paddingInline: noLine && 0,
    borderRadius: "5px",

    width: "100%",
    marginBottom: theme.spacing(1.5),
    height: "fit-content",
  };
});
const ButtonContainer = styled.div(({ theme }) => {
  return {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    gap: theme.spacing(1),
  };
});
const Submit = styled(ButtonContained)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      backgroundColor: theme.palette.action.selected,
      ...FontStyles.semibold12,
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.9),
    },
  };
});
const Clear = styled(ButtonText)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      color: theme.palette.action.selected,
      ...FontStyles.semibold12,
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.9),
    },
  };
});
const OnUse = styled.p(({ theme }) => {
  return {
    ...FontStyles.semibold12,
    paddingInline: theme.spacing(1.625),
    paddingBlock: theme.spacing(1),
    backgroundColor: "#4D585A",
    color: "white",
    borderRadius: theme.spacing(2),
    margin: 0,
  };
});
const Label = styled.p(({ theme, $withError, $color, white }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    padding: 0,
    margin: 0,
    color: "white",
    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
    paddingTop: spacing(2.5),
  };
});
const Value = styled.p(({ theme, $withError, $color, white }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.medium14,
    paddingBlock: spacing(1.25),
    color: "white",
    width: "100%",
    borderBottom: "1px solid #fff",
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
});

const ValueSpan = styled.span(({ theme, $withError, $color, white }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.medium12,

    color: "white",

    margin: 0,
    cursor: "pointer",
    textDecoration: "underline",
    display: "flex",
    gap: "4px",
    alignItems: "center",
  };
});
const Styles = {
  Container,
  Submit,
  Clear,
  ButtonContainer,
  OnUse,
  ValueSpan,
  Value,
  Label,
};

export default Styles;
