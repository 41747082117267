import React, { useContext } from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { StorageContext } from "./contexts/StorageContext";
import pagesConfig from "./config/pagesConfig";
import pages from "./pages/index";
import { Spacing, Theme } from "./config";
import { makeStyles } from "@material-ui/core";
import { Header, Menu } from "./features";
import { hooks, SessionStorage } from "./utils";
import { api } from "./services";
import base64 from "./utils/storage/Base64";

const useStyles = makeStyles((theme: any) => {
  const { breakpoints } = theme;
  return {
    pageContainer: {
      backgroundColor: "#EEEEEE",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      flex: 1,
      padding: 0,
      maxWidth: "80vw",

      backgroundSize: "cover",
      backgroundPosition: "bottom",

      [Theme.Light.breakpoints.down("xl")]: {
        maxWidth: "80vw",
      },

      [Theme.Light.breakpoints.down("md")]: {
        maxWidth: "100vw",
      },
    },
    pageContainerLanding: {
      display: "flex",
      width: "100%",

      flexDirection: "column",

      justifyContent: "flex-start",
      alignItems: "flex-start",
      overflowY: "auto",
      overflowX: "hidden",

      backgroundColor: "#EEEEEE",
    },
    paddingContainer: {
      padding: Spacing(3),
    },
  };
});

interface IUserProps {
  Home: () => Element;
}

function App() {
  const classes = useStyles();
  const location = useLocation();
  const { loading, call } = hooks.useRequest();
  const { isLogged, cancelToken, userData, setUserData } =
    useContext<any>(StorageContext);
  const [menuHeight, setHeight] = React.useState<any>(null);
  const [menu, setMenu] = React.useState<any>(false);
  const [user, setUser] = React.useState<any>(null);

  React.useEffect(() => {
    if (isLogged) {
      const tData = SessionStorage.getItem("token");

      if (tData) {
        const decodeData = base64.decodeJWT(tData);
        if (userData) {
          setUser({ ...userData, ...decodeData });
        } else {
          call("userData", api.getUserData(), async (response) => {
            if (response.ok) {
              SessionStorage.setItem("userData", response.data);
              setUserData({ ...response.data, ...decodeData });
              setUser({ ...response.data, ...decodeData });
            }
          });
        }
      }
    }
  }, [setUser, isLogged, userData]);

  React.useLayoutEffect(() => {
    if (!menuHeight) {
      const mh = document.getElementById("header-menu")?.offsetHeight;

      const fh = document.getElementById("footer")?.offsetHeight;
      let totalH = 0;
      if (mh) {
        totalH += mh;
      }
      if (fh) {
        totalH += fh;
      }

      setHeight(totalH);
    }
  }, [menuHeight]);

  React.useEffect(() => {
    cancelToken.cancel();
  }, [location.pathname]);

  return isLogged ? (
    <>
      <div
        style={{
          transition: "1s",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Menu user={user} openMenu={setMenu} isOpen={menu} />
        <div className={classes.pageContainer}>
          <Header user={user} openMenu={() => setMenu(true)} />
          <div
            className={classes.paddingContainer}
            style={{
              height: menuHeight
                ? `calc(100vh - ${menuHeight}px)`
                : `calc(100vh - ${Spacing(17.25)}px)`,
            }}
          >
            <Routes>
              {pagesConfig.logged.map((item: any, index?: any) => {
                if (item.pages) {
                  return item.pages.map((page: any, index?: any) => {
                    const Component = pages[page.name as keyof IUserProps];

                    function hasAccess(
                      userRoles: string[],
                      pageRoles: string[]
                    ): boolean {
                      if (!pageRoles || pageRoles?.length === 0) {
                        return true;
                      }

                      return userRoles?.some((role) =>
                        pageRoles?.includes(role)
                      );
                    }
                    const canAccess = hasAccess(user?.ROLES, page?.roles);

                    if (canAccess) {
                      return (
                        <Route
                          key={page.navigationId}
                          path={page.path}
                          element={<Component />}
                        />
                      );
                    }
                  });
                }
                return null;
              })}
            </Routes>
          </div>
        </div>{" "}
      </div>
    </>
  ) : (
    <div className={classes.pageContainerLanding}>
      <Routes>
        {pagesConfig.notLogged.map((item?: any, index?: any) => {
          const Component = pages[item.name as keyof IUserProps];
          return (
            <Route
              key={item.path + index}
              path={item.path}
              element={<Component />}
            />
          );
        })}
      </Routes>
    </div>
  );
}

export default App;
