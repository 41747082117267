import React from "react";
import ptBr from "../../../config/texts/pt-br";
import { Input } from "../../../components/index";
import { FormFull } from "form-full";
import { Grid } from "@material-ui/core";
import Styles from "../styles/Styles";
import { masks } from "../../../utils";

function Filters({ onSubmit, loading }) {
  const texts = ptBr.login;

  return (
    <FormFull onSubmit={(data) => onSubmit(data)}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={4}>
          <Input name="fullName" label="Nome Completo" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Input name="email" label="E-mail" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Input name="cpf" label="CPF" maskToSubmit={masks.removeNumberMask} />
        </Grid>
      </Grid>
      <Styles.ButtonContainer>
        <Styles.Clear
          loading={loading}
          disabled={loading}
          fullWidth={false}
          action="clear"
        >
          Limpar
        </Styles.Clear>
        <Styles.Submit
          loading={loading}
          disabled={loading}
          fullWidth={false}
          action="submit"
        >
          Aplicar Filtros
        </Styles.Submit>
      </Styles.ButtonContainer>
    </FormFull>
  );
}

export default Filters;
