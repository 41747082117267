import React, { useState } from "react";
import { Add, ExpandMore, GroupAdd, Visibility } from "@material-ui/icons";
import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table
} from "../../components";
import { SVG, fonts, Spacing } from "../../config";
import Styles from "./styles";
import ModalRegistry, {
  DataEditProps,
  DataRegisterProps
} from "./components/ModalRegistry";
import { HiPencil } from "react-icons/hi2";
import { ImKey } from "react-icons/im";
import { BsPersonFillSlash } from "react-icons/bs";
import { customModal } from "../../components/modals/utils";
import Filters from "./components/Filters";
import { api } from "../../services";
import { hooks } from "../../utils";
import { CircularProgress } from "@material-ui/core";
import ModalRevoke from "./components/ModalRevoke";

export interface OwnersProps {
  id: string;
  email: string;
  name: string;
  cpf: string;
  places: {
    externalId: string;
    id: string;
    name: string;
  }[];
}

const PAGE_SIZE = 10;
export default function RegisterOwners() {
  const { loading, call } = hooks.useRequest();

  const [page, setPage] = React.useState<number>(1);
  const [placeList, setPlaceList] = React.useState<any>([]);
  const [ownersList, setOwnersList] = useState<OwnersProps[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  const registerOwner = async (data: DataRegisterProps) => {
    const { cpf, email, name } = data;
    const studiosIds = data.studios.map((studio) => {
      return studio.value;
    });
    const response = await api.postOwners({
      cpf,
      email,
      name,
      studiosIds: studiosIds ?? []
    });
    if (response.ok) {
      filterOwners();
      customModal.close();
    }
  };

  const editOwner = async (data: DataEditProps) => {
    const { id, name } = data;
    const studiosIds = data.studios.map((studio) => {
      return studio.value;
    });
    const response = await api.putOwners({
      id,
      name,
      studiosIds: studiosIds ?? []
    });
    if (response.ok) {
      filterOwners();
      customModal.close();
    }
  };

  const deleteOwner = async (id: string) => {
    const response = await api.deleteOwners({ id });
    if (response.ok) {
      filterOwners();
      customModal.close();
    }
  };

  const filterOwners = (data?: {
    fullName: string;
    email: string;
    cpf: string;
  }) => {
    api
      .getOwners({
        page: page - 1,
        size: 1000,
        name: data?.fullName ?? "",
        cpf: data?.cpf ?? "",
        email: data?.email ?? ""
      })
      .then(({ data }) => {
        setOwnersList(data.content);
        setTotalPages(data.totalPages);
      });
  };

  const Mount = React.useCallback(() => {
    call(
      null,
      api.getPlaces({
        page: 0,
        size: 999999
      }),
      async (response) => {
        if (response.ok) {
          const list = response?.data?.content?.map((item) => {
            return { label: item.name, value: item.id };
          });
          setPlaceList(list);
        }
      }
    );
    filterOwners();
  }, [page]);

  React.useEffect(() => {
    Mount();
  }, [Mount]);

  const getButtons = (item: any): any[] => {
    return [
      {
        label: "Visualizar Dados",
        icon: Visibility,
        onClick: () => openViewModal(item)
      },
      {
        label: "Editar Dados",
        icon: HiPencil,
        onClick: () => openEditModal(item)
      },

      {
        label: "Revogar Acesso",
        icon: BsPersonFillSlash,
        onClick: () => openRevokeModal(item)
      }
    ];
  };

  const openEditModal = (data) => {
    if (data) {
      customModal.setInfos(
        "Editar Dados do Proprietário",
        [],
        null,
        null,
        <ModalRegistry
          data={data}
          placeList={placeList}
          onSubmit={editOwner}
        />,
        false,
        true,
        <HiPencil />
      );
    }
  };

  const openViewModal = (data) => {
    if (data) {
      customModal.setInfos(
        "Visualizar Dados do Proprietário",
        [],
        null,
        null,
        <ModalRegistry data={data} view placeList={placeList} />,
        false,
        true,
        <Visibility />
      );
    }
  };

  const openRevokeModal = (data) => {
    if (data) {
      customModal.setInfos(
        "Revogar Acesso de Proprietário",
        [],
        null,
        null,
        <ModalRevoke
          data={data}
          onSubmit={deleteOwner}
          placeList={placeList}
        />,
        false,
        true,
        <BsPersonFillSlash />
      );
    }
  };

  const createOwner = () => {
    customModal.setInfos(
      "Cadastrar Proprietário",
      [],
      null,
      null,
      <ModalRegistry
        placeList={placeList}
        onSubmit={(data) => {
          registerOwner(data);
        }}
      />,
      false,
      true,
      <GroupAdd />
    );
  };

  return (
    <>
      <Styles.ContainerSecondary>
        <Styles.RegitryContainer>
          <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
            Cadastrar novo Proprietário
          </CustomText>
          <CustomText
            style={{ paddingRight: "8px" }}
            fontSize={1.75}
            textColor="#FFFFFF"
            fontFamily={fonts.regular}
          >
            Alguns dados básicos serão solicitados para o cadastro de um novo
            proprietário.
          </CustomText>
        </Styles.RegitryContainer>
        <Styles.RegitryButton
          startIcon={<Add />}
          fullWidth={false}
          onClick={createOwner}
        >
          Cadastrar
        </Styles.RegitryButton>
      </Styles.ContainerSecondary>

      <Styles.ContainerFilter>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Filtrar Proprietários Cadastrados
        </CustomText>
        <Filters
          onSubmit={(data) => {
            filterOwners(data);
          }}
          loading={false}
        />
      </Styles.ContainerFilter>

      <Styles.Container noLine>
        <CustomText
          style={{ padding: "16px 20px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Lista de Proprietários Cadastrados
        </CustomText>
        {loading ? (
          <Styles.ContainerList>
            <CircularProgress />
          </Styles.ContainerList>
        ) : ownersList.length > 0 ? (
          <ContentFeedback data={ownersList} loading={false}>
            <Table
              lastAlign={false}
              data={ownersList}
              renderItemColumns={(item) => [
                item.name,
                item.email,
                item.cpf,
                <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                  Opções
                </ButtonMenu>
              ]}
              headers={{
                table: ["NOME COMPLETO", "EMAIL", "CPF", "AÇÃO"],
                keys: []
              }}
            />
            <Pagination
              style={{ padding: "16px 20px", paddingTop: 0 }}
              page={page}
              totalPages={totalPages}
              setPage={(number) => setPage(number)}
            />
          </ContentFeedback>
        ) : (
          <Styles.ContainerList>
            <CustomText
              style={{ padding: "20px" }}
              fontSize={2}
              textColor="#24272A"
              fontFamily={fonts.bold}
            >
              Sem Dados
            </CustomText>
          </Styles.ContainerList>
        )}
      </Styles.Container>
    </>
  );
}
