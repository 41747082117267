import Styles from "../styles/Styles";

import { CustomText, DefaultLogo, FontStyles } from "../../../components";
import colors from "../../../config/colors";
import { fonts, pagesConfig } from "../../../config";
import { useLocation } from "react-router-dom";

type CustomHeaderProps = {
  openMenu: () => void;
  user: any;
};

function Header({ openMenu, user }: CustomHeaderProps) {
  const location = useLocation();
  const classes = Styles.useStyles();
  const getCurrentPage = (path) => {
    let currentPage = <></>;

    pagesConfig.logged.map((item) => {
      return item.pages.map((element: any) => {
        if (path === element?.path) {
          currentPage = (
            <div>
              <CustomText
                fontSize={18 / 8}
                fontFamily={fonts.bold}
                textColor="#4D585A"
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  paddingRight: "8px"
                }}
              >
                {element?.icon && (
                  <element.icon alt="" className={classes.icon} />
                )}
                {element?.title}
              </CustomText>
              <Styles.TextDescript>{element?.description}</Styles.TextDescript>
            </div>
          );
        }
        return element;
      });
    });
    return currentPage;
  };

  const roleUser = () => {
    if (user?.ROLES.includes("ROLE_ADMIN_HABITAT")) {
      return "SuperAdmin";
    } else if (user?.ROLES.includes("ROLE_ADMIN")) {
      return "Admin";
    } else if (user?.ROLES.includes("ROLE_MANAGER")) {
      return "Gestor";
    }
    return "";
  };

  return (
    <Styles.Container id="header-menu">
      <Styles.FirstContentGroup>
        <Styles.Rectangle onClick={openMenu}>
          <Styles.IconMenu />
        </Styles.Rectangle>
        {getCurrentPage(location.pathname)}
      </Styles.FirstContentGroup>

      <Styles.UserContentGroup>
        <CustomText
          textColor={colors.gray}
          fontFamily={fonts.semibold}
          fontSize={2}
        >
          {user?.name}
        </CustomText>
        <CustomText
          textColor={colors.black}
          fontFamily={fonts.regular}
          fontSize={1.75}
        >
          {roleUser()}
        </CustomText>
      </Styles.UserContentGroup>
    </Styles.Container>
  );
}

export default Header;
