import React, { useState } from "react";
import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table,
} from "../../components";
import { fonts, SVG } from "../../config";
import Styles from "./styles/Styles";
import { Add, Close, ExpandMore } from "@material-ui/icons";
import Filters from "./components/Filters";
import { hooks, masks, SessionStorage } from "../../utils";
import { customModal } from "../../components/modals/utils";
import { BsPersonFillSlash } from "react-icons/bs";
import { HiPencil } from "react-icons/hi2";
import ModalRegistry, {
  DataEditProps,
  DataRegisterProps,
} from "./components/ModalRegistry";
import { api } from "../../services";
import base64 from "../../utils/storage/Base64";

export interface ManagerProps {
  id: string;
  email: string;
  name: string;
  cpf: string;
  places: {
    externalId: string;
    id: string;
    name: string;
  }[];
}

export interface OwnersProps {
  id: string;
  email: string;
  name: string;
  cpf: string;
  places: {
    externalId: string;
    id: string;
    name: string;
  }[];
}

function MangerRegistryPage() {
  const { loading, call } = hooks.useRequest();

  const [page, setPage] = React.useState<number>(1);
  const [managerList, setManagerList] = useState<ManagerProps[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [ownersList, setOwnersList] = useState<
    { label: string; value: string; places: { id: string; name: string }[] }[]
  >([]);
  const [isManager, setIsManager] = useState(false);

  const Mount = React.useCallback(() => {
    const tData = SessionStorage.getItem("token");
    const decodeData = base64.decodeJWT(tData);

    function hasAccess(userRoles: string[], pageRoles: string[]): boolean {
      if (!pageRoles || pageRoles?.length === 0) {
        return true;
      }

      return userRoles?.some((role) => pageRoles?.includes(role));
    }

    const canAccess = hasAccess(decodeData?.ROLES, ["ROLE_ADMIN_HABITAT"]);

    if (canAccess) {
      call(
        null,
        api.getOwners({
          page: 0,
          size: 999999,
          cpf: "",
          email: "",
          name: "",
        }),
        async (response) => {
          if (response.ok) {
            const list = response?.data?.content?.map((item) => {
              return {
                label: item.email,
                value: item.id,
                places: item.places.map((place) => {
                  return {
                    label: place.name,
                    value: place.id,
                  };
                }),
              };
            });
            setOwnersList(list);
          }
        }
      );
    } else {
      setIsManager(true);
      call(
        null,
        api.getPlaces({
          page: 0,
          size: 999999,
        }),
        async (response) => {
          if (response.ok) {
            setOwnersList([
              {
                label: "Gestor",
                value: "gestor",
                places: response?.data?.content,
              },
            ]);
          }
        }
      );
    }

    filterManager();
  }, [page]);

  React.useEffect(() => {
    Mount();
  }, [Mount]);

  const registerOwner = async (data: DataRegisterProps) => {
    const { cpf, email, name } = data;
    const studiosIds = (data.studios ?? [])
      .map((studio) => studio.value)
      .filter((id) => id !== "all");

    const usersIds = (data.owners ?? [])
      .map((owner) => owner.value)
      .filter((id) => id !== "all");

    const response = await api.postManagers({
      cpf,
      email,
      name,
      studiosIds: studiosIds ?? [],
      usersIds: usersIds ?? [],
    });
    if (response.ok) {
      filterManager();
      customModal.close();
    }
  };

  const editManager = async (data: DataEditProps) => {
    const { id, name, cpf } = data;
    const studiosIds = data.studios.map((studio) => {
      return studio.value;
    });
    const usersIds = data.owners.map((owner) => {
      return owner.value;
    });
    const response = await api.putManagers({
      id,
      name,
      cpf,
      studiosIds: studiosIds ?? [],
      usersIds: usersIds ?? [],
    });
    if (response.ok) {
      filterManager();
      customModal.close();
    }
  };

  const deleteManager = async (id: string) => {
    const response = await api.deleteManagers({ id });
    if (response.ok) {
      filterManager();
      customModal.close();
    }
  };

  const filterManager = (data?: {
    fullName: string;
    email: string;
    cpf: string;
  }) => {
    api
      .getManagers({
        page: page - 1,
        size: 1000,
        name: data?.fullName ?? "",
        cpf: data?.cpf ?? "",
        email: data?.email ?? "",
      })
      .then(({ data }) => {
        setManagerList(data.content);
        setTotalPages(data.totalPages);
      });
  };

  const getButtons = (item: any): any[] => {
    if (isManager) {
      return [];
    } else {
      return [
        /* {
          label: "Editar Dados",
          icon: HiPencil,
          onClick: () => openEditModal(item),
        }, */

        {
          label: "Deletar Gestor",
          icon: BsPersonFillSlash,
          onClick: () => openRevokeModal(item.id),
        },
      ];
    }
  };

  const openRegisterModal = () => {
    customModal.setInfos(
      "Cadastrar Gestor",
      [],
      null,
      null,
      <ModalRegistry
        isManager={isManager}
        ownersList={ownersList}
        onSubmit={registerOwner}
      />,
      false,
      true,
      <SVG.addUser />
    );
  };

  const openEditModal = (manager) => {
    if (manager) {
      customModal.setInfos(
        "Editar Dados do Gestor",
        [],
        null,
        null,
        <ModalRegistry
          isManager={isManager}
          ownersList={ownersList}
          data={manager}
          onSubmit={editManager}
        />,
        false,
        true,
        <SVG.editPencil />
      );
    }
  };

  const openRevokeModal = (id: string) => {
    customModal.setInfos(
      "Deletar Gestor",
      ["Continuar e revogar acesso deste gestor?"],
      {
        label: "Sim, Revogar",
        onClick: () => {
          deleteManager(id);
        },
      },
      {
        label: "Voltar",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      false,
      true,
      <Close />
    );
  };

  return (
    <>
      <Styles.PageNameContainer>
        <Styles.PageNameContent>
          <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
            Cadastrar Gestor
          </CustomText>

          <CustomText
            style={{ paddingRight: "8px" }}
            fontSize={1.75}
            textColor="#FFFFFF"
            fontFamily={fonts.regular}
          >
            Alguns dados básicos serão solicitados para o cadastro de um novo
            gestor.
          </CustomText>
        </Styles.PageNameContent>

        <Styles.RegitryButton
          startIcon={<Add />}
          fullWidth={false}
          onClick={openRegisterModal}
        >
          Cadastrar
        </Styles.RegitryButton>
      </Styles.PageNameContainer>

      <Styles.ContainerFilter>
        <CustomText
          style={{ paddingBottom: "25px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Filtrar Gestores Cadastrados
        </CustomText>
        <Filters
          onSubmit={(data) => {
            filterManager(data);
          }}
          loading={false}
        />
      </Styles.ContainerFilter>

      <Styles.Container>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Lista de Gestores Cadastrados
        </CustomText>

        <ContentFeedback data={managerList} loading={false}>
          <Table
            lastAlign={false}
            data={managerList}
            renderItemColumns={(item) => [
              item.name,
              item.email,
              masks.CPF(item.cpf),
              null,
              null,
              <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                Opções
              </ButtonMenu>,
            ]}
            headers={{
              table: ["NOME COMPLETO", "EMAIL", "CPF", "", "", "AÇÃO"],
              keys: [],
            }}
          />
          <Pagination
            style={{ padding: "16px 20px", paddingTop: 0 }}
            page={page}
            totalPages={totalPages}
            setPage={(number) => setPage(number)}
          />
        </ContentFeedback>
      </Styles.Container>
    </>
  );
}

export default MangerRegistryPage;
